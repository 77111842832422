import { useEffect, useState } from 'react';
import api from '../../services/api';
import { Link } from 'react-router-dom';
import './home.css';


export default function Home() {

  const [filmes, setFilmes] = useState([]);
  const [loading, setLoading] = useState(true);


  useEffect(() => {

    async function loadFilmes() {
      const response = await api.get('r-api/?api=filmes');
      setFilmes(response.data);
      //false quando carregar
    setLoading(false);
    }
    loadFilmes();

    

  }, [filmes]);

  if (loading) {
    return (
      <div>
        <h1>Carregando...</h1>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="lista_filmes">
        {
          filmes.map((filme) => {
            return (
              <article key={filme.id}>
                <strong>{filme.nome}</strong>
                <img src={filme.foto} alt={filme.nome} />
                <Link to={`/filme/${filme.id}`}>Acessar</Link>
              </article>
            );
          })
        }
      </div>
    </div>
  )
}
