import { useEffect, useState } from 'react';
import './filme.css';
import { useParams, useHistory } from 'react-router-dom';
import api from '../../services/api';
import { toast } from 'react-toastify';

export default function Filme(){
    const { id } = useParams();
    const history = useHistory();

    const [filme, setFilme] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect( () => {
        async function loadFilme(){
            const response = await api.get(`r-api/?api=filmes/${id}`);

            if( response.data.length === 0 ) {
                history.replace('/');
                return;
            }

            setFilme(response.data);
            setLoading(false);
        }
        loadFilme();

    return;

    }, [history, id] );

    function salvaFilme(){
        //Guarda a lista ja salva
        const minhaLista = localStorage.getItem('filmes');

        //Guarda o array e caso não tenha nenhum salvo,  guarda vazio
        let filmesSalvos = JSON.parse(minhaLista) || [];

        // Verifica se ja tem o filme salvo na lista e ignora
        //Ele compara se tem o id do filme salva igual ao id que esta sendo passado
        //retorn true ou false em hasFilme
        const hasFilme = filmesSalvos.some( (filmeSalvo) => filmeSalvo.id === filme.id);

        if(hasFilme) {
            toast.error('Filme ja está cadastrado!');    
            return;
        }

        //Adiciona o filme atual na lista de filmes ja salvos
        filmesSalvos.push(filme);

        //guarda a lista atualiza no localStorage
        localStorage.setItem('filmes', JSON.stringify(filmesSalvos));
        toast.info('Adicionado com sucesso!');        
    }

    if(loading){
        return(
            <div>
                <h1>Carregando...</h1>
            </div>
        );
    }

    return(
        <div className="filme-info">
            <h1>{filme.nome}</h1>
            <img src={filme.foto} alt={filme.nome} />
            <h3>Sinopse</h3>
            <p>{filme.sinopse}</p>
            <div className="botoes">
                <button onClick={salvaFilme}>Salvar</button>
                <button>
                    <a href={`https://youtube.com/search_query=${filme.nome}`}>Trailer</a>
                </button>
            </div>
        </div>
    );
}