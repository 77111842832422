
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './favoritos.css';
import { toast } from 'react-toastify';

export default function Favoritos() {
    const [filmes, setFilmes] = useState([]);

    useEffect(() => {

        const minhaLista = localStorage.getItem('filmes');
        setFilmes(JSON.parse(minhaLista) || []);

    }, []);

    function handleDelete(id){
        //Buscar todo os itens diferente do item id que estamos passando
        let filtroFilmes = filmes.filter( (item) => {
            return (item.id !== id);
        });
        setFilmes(filtroFilmes);
        localStorage.setItem('filmes', JSON.stringify(filtroFilmes));
        toast.success('Excluído com sucesso!');
    }



    return (
        <div>
            <h1>Filmes Favoritos</h1>

            {
                //Exemplo de renderização condicional, parecdio com operador ternario
                filmes.length === 0 && <span>Não tem filmes Salvos</span>
            }

            <div className="container">
                <ul>
                    {
                        filmes.map((item) => {
                            return (
                                <li key={item.id}>
                                    <span>{item.nome}</span>
                                    <div>
                                        <Link to={`/filme/${item.id}`}> + Detalhes </Link>
                                        <button onClick={ () => handleDelete(item.id) }>Excluir</button>
                                    </div>
                                </li>
                            );
                        })
                    }
                </ul>
            </div>

        </div>
    );
}